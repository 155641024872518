/* import logo from './assets/images/team1.jpg'; */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Team from './components/Team';
import Projects from './components/Projects';
import AboutMe from './components/AboutMe';
import Portfolio from './components/Portfolio';
import MediaHub from './components/MediaHub';
import Contact from './components/Contact';
/* import { useTranslation } from 'react-i18next';
import LanguageSelector from './components/LanguageSelector'; */

import './App.css';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/team" element={<Team />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about-me" element={<AboutMe />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/mediahub" element={<MediaHub />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;



// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Projects from './components/Projects';
// import Home from './components/Home';
// import Team from './components/Team';
// import AboutMe from './components/AboutMe';
// import Portfolio from './components/Portfolio';
// import MediaHub from './components/MediaHub';
// import ContactUs from './components/ContactUs';

// const App = () => (
//   <Router>
//     <Switch>
//       <Route exact path="/" component={Home} />
//       <Route path="/projects" component={Projects} />
//       <Route path="/our-team" component={Team} />
//       <Route path="/about-me" component={AboutMe} />
//       <Route path="/portfolio" component={Portfolio} />
//       <Route path="/media-hub" component={MediaHub} />
//       <Route path="/contact-us" component={ContactUs} />
//       {/* Add more routes as needed */}
//     </Switch>
//   </Router>
// );

// export default App;






/* Langs */
/* 
const App = () => {
  const { t } = useTranslation();

  return (
    <div className="app-container">
      <header className="app-header">
        <h1>{t('home')}</h1>
        <p>{t('slogan')}</p>
      </header>
      <LanguageSelector />             /// Language selector component 
      <nav className="app-nav">
        <ul>
          <li><a href="#home">{t('home')}</a></li>
          <li><a href="#team">{t('team')}</a></li>
          <li><a href="#projects">{t('projects')}</a></li>
          <li><a href="#contact">{t('contact')}</a></li>
          <li><a href="#about">{t('about')}</a></li>
          <li><a href="#services">{t('services')}</a></li>
          <li><a href="#portfolio">{t('portfolio')}</a></li>
          <li><a href="#careers">{t('careers')}</a></li>
          <li><a href="#blog">{t('blog')}</a></li>
          <li><a href="#faq">{t('faq')}</a></li>
          <li><a href="#testimonials">{t('testimonials')}</a></li>
          <li><a href="#privacy">{t('privacy')}</a></li>
          <li><a href="#terms">{t('terms')}</a></li>
        </ul>
      </nav>
      /* Other components and content 
      <footer className="app-footer">
        <p>{t('privacy')}</p>
        <p>{t('terms')}</p>
      </footer>
    </div>
  );
}; */





/*

import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/